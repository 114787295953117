var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as CUTIL from "@utils/commUtils";
import * as CONST from "@utils/Const";
import { changeLang } from "@utils/langs/i18nUtils";
import { genUUID } from "@utils/uniqueUtils";
import { getDetailUserInfoR } from "@utils/user/userUtils";
export const initRoute = (setRecoilLangIds, setRecoilLangs, params) => {
    (() => __awaiter(void 0, void 0, void 0, function* () {
        const uuid = genUUID();
        const uuid_h = localStorage.getItem("uuid_h");
        const params = new URLSearchParams(location.search);
        const paramsLagns = params.get("lang");
        if (uuid_h == uuid) {
            let language = localStorage.getItem(CONST.STR_LANG_I18N) == CONST.STR_LANG_ENG ? CONST.STR_APILANG_ENG : CONST.STR_APILANG_KOR;
            let enUrl = paramsLagns == "en" ? CONST.STR_APILANG_ENG : language;
            // let language =  CONST.STR_APILANG_KOR
            const frontLang = CUTIL.frontLangSet(enUrl);
            const langId = CUTIL.LangIdSet(frontLang);
            localStorage.setItem("langId", langId.toString());
            yield changeLang(frontLang);
            setRecoilLangIds(langId);
            setRecoilLangs(frontLang);
            //
        }
        else {
            let language = localStorage.getItem("langs_s") == CONST.STR_LANG_ENG ? CONST.STR_APILANG_ENG : CONST.STR_APILANG_KOR;
            // let language =  CONST.STR_APILANG_KOR
            const frontLang = CUTIL.frontLangSet(language);
            const langId = CUTIL.LangIdSet(frontLang);
            localStorage.setItem("langId", langId.toString());
            yield changeLang(frontLang);
            setRecoilLangIds(langId);
            setRecoilLangs(frontLang);
            //
        }
    }))();
};
export const authRoute = (setRecoilLangIds, setRecoilLangs, userInfo, setRecoilUserInfo) => {
    if (!userInfo.hasOwnProperty(userInfo.userId) || userInfo.userId.length <= 0) {
        // 리프레쉬 등
        const sesToken = sessionStorage.getItem(CONST.STR_TOKEN);
        const sesTokenExpireTime = sessionStorage.getItem(CONST.STR_TOKEN_EXPIRETIME);
        const userRole = localStorage.getItem(CONST.STR_USERROLE);
        const refreshToken = localStorage.getItem(CONST.STR_REFRESHTOKEN);
        const refreshTokenExpireTime = localStorage.getItem(CONST.STR_REFRESHTOKEN_EXPIRETIME);
        const loginInfo = {
            role: userRole,
            token: sesToken,
            tokenExpireTime: sesTokenExpireTime,
            refreshToken: refreshToken,
            refreshTokenExpireTime: refreshTokenExpireTime,
        };
        (() => __awaiter(void 0, void 0, void 0, function* () {
            // IFFE(Immediately Invoked Function Expression)은 함수가 정의되자마자 실행되는 자바스크립트 기술입니다.
            const detailUserInfo = yield getDetailUserInfoR(loginInfo);
            setRecoilUserInfo({
                loginTime: detailUserInfo.loginTime,
                userId: detailUserInfo.userId,
                email: detailUserInfo.email,
                userName: detailUserInfo.userName,
                phoneNumber: detailUserInfo.phoneNumber,
                companyName: detailUserInfo.companyName,
                zoneName: detailUserInfo.zoneName,
                department: detailUserInfo.department,
                role: detailUserInfo.role,
                language: detailUserInfo.language,
                classificationCode: detailUserInfo.classificationCode,
                classification: detailUserInfo.classification,
                agreeMailReceipt: detailUserInfo.agreeMailReceipt,
                agreeTos: detailUserInfo.agreeTos,
                agreePersonalInfo: detailUserInfo.agreePersonalInfo,
                agreeData: detailUserInfo.agreeData,
                userIdPk: detailUserInfo.userIdPk,
                isAutomatedNickName: detailUserInfo.isAutomatedNickName,
                nickName: detailUserInfo.nickName,
                userRank: detailUserInfo.userRank,
                userImage: detailUserInfo.userImage,
                loginInfo: loginInfo,
            });
            const params = new URLSearchParams(location.search);
            const paramsLagns = params.get("lang");
            // let language = (localStorage.getItem(CONST.STR_LANG_I18N) == CONST.STR_LANG_KOR) ? CONST.STR_APILANG_KOR : CONST.STR_APILANG_ENG
            const frontLang = CUTIL.frontLangSet(detailUserInfo.language);
            const langId = CUTIL.LangIdSet(frontLang);
            localStorage.setItem("langId", langId.toString());
            yield changeLang(frontLang);
            setRecoilLangIds(langId);
            setRecoilLangs(frontLang);
        }))();
    }
};
