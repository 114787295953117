import { jsx as _jsx } from "react/jsx-runtime";
import { DashBoardLayout } from "@layout/DashBoardLayout";
import PrivateRoute from "@router/PrivateRoute";
import PublicRoute from "@router/PublicRoute";
import "@static/css/style.css";
import * as CONST from "@utils/Const";
import { lazy, Suspense } from "react";
import { Navigate } from "react-router-dom";
const SuspenseLoader = () => {
    return (_jsx("div", Object.assign({ className: "loading", style: { overflowY: "hidden" } }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB85C\uB529\uC911" })) })));
};
const Loader = (Component) => (props) => (_jsx(Suspense, Object.assign({ fallback: _jsx(SuspenseLoader, {}) }, { children: _jsx(Component, Object.assign({}, props)) })));
const Login = Loader(lazy(() => import("@components/login/Login")));
const PotalCommon = Loader(lazy(() => import("@components/layout/IntroductionLayout")));
const DashBoardCommon = Loader(lazy(() => import("@components/layout/MainLayout")));
const ManagementCommon = Loader(lazy(() => import("@components/layout/ManagementLayout")));
const IntroLayout = Loader(lazy(() => import("@components/layout/IntroLayout")));
const EhpCertification = Loader(lazy(() => import("@components/layout/ServiceRequestLayout")));
const EhpPdfViewer = Loader(lazy(() => import("@components/common/pdfviewer/EhpPdfViewer")));
const ServiceRequestLayout = Loader(lazy(() => import("@components/layout/ServiceRequestLayout")));
const NotFound = Loader(lazy(() => import("@screens/error/NotFound")));
const SystemError = Loader(lazy(() => import("@screens/error/SystemError")));
const routes = [
    {
        element: _jsx(PublicRoute, { component: null }),
        children: [
            {
                path: CONST.URL_LOGIN,
                element: _jsx(Login, {}),
            },
            {
                path: CONST.URL_COMM_PDFVIEWER,
                element: _jsx(EhpPdfViewer, {}),
            },
            {
                path: CONST.URL_VERIFYEMAIL,
                element: _jsx(EhpCertification, {}),
            },
            {
                path: "/",
                element: _jsx(Navigate, { to: CONST.URL_MAIN, replace: true }),
            },
            {
                path: CONST.URL_MAIN,
                element: _jsx(PotalCommon, {}),
            },
            {
                path: CONST.URL_WHATWEDO,
                element: _jsx(PotalCommon, {}),
            },
            {
                path: CONST.URL_MARKETPLACE,
                element: _jsx(PotalCommon, {}),
            },
            {
                path: CONST.URL_CONTACTUS,
                element: _jsx(PotalCommon, {}),
            },
            {
                path: CONST.URL_COMMUNITY,
                element: _jsx(PotalCommon, {}),
            },
            {
                path: CONST.URL_MYPAGE,
                element: _jsx(PotalCommon, {}),
            },
            {
                path: CONST.URL_VERIFYEMAILCOMMUNITY,
                element: _jsx(PotalCommon, {}),
            },
        ],
    },
    {
        element: _jsx(PrivateRoute, { component: DashBoardLayout }),
        children: [
            {
                path: CONST.URL_MONITORING,
                element: _jsx(DashBoardCommon, {}),
            },
            {
                path: CONST.URL_TODAYINSPECTION,
                element: _jsx(DashBoardCommon, {}),
            },
            {
                path: CONST.URL_ENERGYSOLUTION,
                element: _jsx(DashBoardCommon, {}),
            },
            {
                path: CONST.URL_REPORT,
                element: _jsx(DashBoardCommon, {}),
            },
            {
                path: CONST.URL_OPERATION,
                element: _jsx(DashBoardCommon, {}),
            },
            {
                path: CONST.URL_CONSULTION,
                element: _jsx(DashBoardCommon, {}),
            },
        ],
    },
    {
        element: _jsx(PrivateRoute, { component: DashBoardLayout }),
        children: [
            {
                path: CONST.URL_APPROVALMANAGEMENT,
                element: _jsx(ManagementCommon, {}),
            },
            {
                path: CONST.URL_USERMANAGEMENT,
                element: _jsx(ManagementCommon, {}),
            },
            {
                path: CONST.URL_SITEMANAGEMENT,
                element: _jsx(ManagementCommon, {}),
            },
            {
                path: CONST.URL_MENUMANAGEMENT,
                element: _jsx(ManagementCommon, {}),
            },
            {
                path: CONST.URL_CHECKSHEETMANAGEMENT,
                element: _jsx(ManagementCommon, {}),
            },
            {
                path: CONST.URL_ECONSULTMANAGEMENT,
                element: _jsx(ManagementCommon, {}),
            },
            {
                path: CONST.URL_SPGMANAGEMENT,
                element: _jsx(ManagementCommon, {}),
            },
            {
                path: CONST.URL_SOLUTIONMANAMENT,
                element: _jsx(ManagementCommon, {}),
            },
            {
                path: CONST.URL_COMMUNITYMANAGEMENT,
                element: _jsx(ManagementCommon, {}),
            },
            {
                path: CONST.URL_INFOMANAGEMENT,
                element: _jsx(ManagementCommon, {}),
            },
            {
                path: CONST.URL_ENERGYSOLUTIONMANAGEMENT,
                element: _jsx(ManagementCommon, {}),
            },
            {
                path: CONST.URL_INTRODUCTIONINQUIRY,
                element: _jsx(ServiceRequestLayout, {}),
            },
        ],
    },
    {
        element: _jsx(PrivateRoute, { component: DashBoardLayout }),
        children: [
            {
                path: CONST.URL_HELP,
                element: _jsx(IntroLayout, {}),
            },
        ],
    },
    {
        path: "*",
        element: _jsx(Navigate, { to: CONST.URL_NOT_FOUND, replace: true }),
    },
    {
        element: _jsx(PublicRoute, { component: DashBoardLayout }),
        children: [
            {
                path: CONST.URL_NOT_FOUND,
                element: _jsx(NotFound, {}),
            },
            {
                path: CONST.URL_SYSTEM_ERROR,
                element: _jsx(SystemError, {}),
            },
        ],
    },
];
export default routes;
