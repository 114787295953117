import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { langId, langState } from "@recoil/langState";
import { authState, demo, userInfoState } from "@recoil/userState";
import * as CONST from "@utils/Const";
import { authRoute, initRoute } from "@utils/RouterUtils";
import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
const PrivateRoute = ({ component: RouteComponent }) => {
    const location = useLocation();
    const isAuth = useRecoilValue(authState);
    const params = new URLSearchParams(location.search);
    const setRecoilLangIds = useSetRecoilState(langId);
    const setRecoilLangs = useSetRecoilState(langState);
    const [userInfo, setRecoilUserInfo] = useRecoilState(userInfoState);
    const setDemos = useSetRecoilState(demo);
    useEffect(() => {
        if (isAuth) {
            authRoute(setRecoilLangIds, setRecoilLangs, userInfo, setRecoilUserInfo);
        }
        else {
            initRoute(setRecoilLangIds, setRecoilLangs, params);
        }
    }, []);
    const sesDemo = localStorage.getItem("demo");
    useEffect(() => {
        setDemos(!!sesDemo);
    }, [sesDemo]);
    return _jsx(_Fragment, { children: isAuth ? _jsx(RouteComponent, {}) : _jsx(Navigate, { to: CONST.URL_MAIN }) });
};
export default PrivateRoute;
