import { atom } from "recoil";
/* SEI 01 */
// 최초 회사,사업장 저장
export const mainState = atom({
    key: "mainState",
    default: {
        companyId: "",
        zoneId: "",
    },
});
// 오늘의 점검 상태 값
export const curSchduleTreeState = atom({
    key: "curSchduleTreeState",
    default: {
        company: { companyId: "", companyName: "" },
        zone: { zoneId: "", zoneName: "", zoneBoolean: true },
        subZone: { subZoneId: "", subZoneName: "", subZoneBoolean: false },
        room: { roomId: "", roomName: "", roomBoolean: false },
        spg: { companySpgId: -1, spgId: -1, spgName: "", spgBoolean: false },
    },
});
// 모니터링 상태 값
export const curMonitoringTreeState = atom({
    key: "curMonitoringTreeState",
    default: {
        company: { companyId: "", companyName: "" },
        zone: { zoneId: "", zoneName: "", zoneBoolean: true },
        subZone: { subZoneId: "", subZoneName: "", subZoneBoolean: false },
        room: { roomId: "", roomName: "", roomBoolean: false },
        spg: { companySpgId: -1, spgId: -1, spgName: "", spgBoolean: false },
    },
});
// 단일 레포트 상태 값
export const curReportState = atom({
    key: "curReportState",
    default: {
        companyId: "",
        subZoneId: "",
        name: "",
        roomId: "",
        roomName: "",
        spgId: -1,
        spgName: "",
    },
});
// 종합 레포트 상태 값
export const curTotalState = atom({
    key: "curTotalState",
    default: {
        companyId: "",
        companyName: "",
        id: -1,
    },
});
// 설비 유형에 대한 상태 값
export const SpgTreeState = atom({
    key: "SpgTreeState",
    default: {
        spg: { companySpgId: -1, spgId: -1, spgName: "" },
    },
});
// 모니터링 설비 유형 상태 값
export const SpgFilterState = atom({
    key: "SpgFilterState",
    default: {
        spg: { companySpgId: -1, spgId: -1, spgName: "" },
        group: { groupId: -1, groupName: "" },
        ckeck: { checkItem: "" },
    },
});
export const curAdminTreeState = atom({
    key: "curAdminTreeState",
    default: {
        company: { companyId: "", companyName: "" },
        zone: { zoneId: "", zoneName: "" },
        subZone: { subZoneId: "", subZoneName: "" },
        room: { roomId: "", roomName: "" },
    },
});
export const adminTypeState = atom({
    key: "adminTypeState",
    default: "zone",
});
export const adminTabState = atom({
    key: "adminTabState",
    default: "SITE",
});
export const adminApprovalState = atom({
    key: "adminApprovalState",
    default: {
        approval: -1,
        deniedReason: "",
    },
});
export const curConsultInfo = atom({
    key: "curConsultInfo",
    default: {
        company: { companyId: "", companyName: "" },
        zone: { zoneId: "", zoneName: "" },
        reUrl: "",
        type: "",
        itemInof: [],
    },
});
export const curConsultingState = atom({
    key: "curConsultingState",
    default: {
        companyId: "",
        zoneId: "",
        name: "",
    },
});
export const InspectionState = atom({
    key: "InspectionState",
    // key의 값은 항상 고유값이어야 합니다.
    default: [],
});
export const checkTypeState = atom({
    key: "checkTypeState",
    // key의 값은 항상 고유값이어야 합니다.
    default: {
        checkType: "daily",
        checkOn: false,
        nextStep: "",
        preStep: "",
        assessmentId: -1,
        checkitemId: -1,
        preCheckitemId: -1,
        checkvalueId: -1,
        itemNum: -1,
        totalComment: "",
        itemInfo: {
            id: -1,
            itemId: "",
            itemName: "",
            panelName: "",
            serialNo: "",
            spgId: -1,
        },
        chekTypeInfo: {
            checkTypeId: -1,
            checkTypeName: "",
        },
        chekSubTypeInfo: {
            checkSubTypeId: -1,
            checkSubName: "",
        },
        chekVersionTypeInfo: {
            checkVersionId: -1,
            versionNo: "",
        },
        assessmentInfo: {
            isTempSave: true,
            reportId: -1,
        },
        checkvalue: {
            action: "",
            comment: "",
            input: -1,
            isChecked: false,
            value: -1,
        },
        listType: -1,
        processRate: -1,
        startPage: -1,
        endpage: -1,
        dueDate: "",
        instrument: -1,
        countOpenClose: -1,
        treeName: "",
        schdeulerId: -1,
        disabled: false,
        period: -1,
        checkRef: [],
        isReportUpdated: false,
        inspector: "",
    },
});
export const reportTypeState = atom({
    key: "reportTypeState",
    // key의 값은 항상 고유값이어야 합니다.
    default: {
        checkType: "main",
        smenu: "report",
        assessmentId: -1,
        checkSubTypeName: "",
        checkTypeName: "",
        checkVersionId: -1,
        companySpgName: "",
        isReportUpdated: false,
        itemReportDto: {
            itemId: "",
            itemIdPk: -1,
            itemName: "",
            memo: "",
            responsible: "",
            serialNo: "",
        },
        totalInfo: {
            title: "",
            totalId: -1,
        },
        panelName: "",
        reportId: -1,
        roomName: "",
        subZoneName: "",
        totalComment: "",
        totalScore: -1,
        countOpenClose: -1,
    },
});
export const alertState = atom({
    key: "alertState",
    // key의 값은 항상 고유값이어야 합니다.
    default: {
        header: "",
        layerId: "",
        type: "",
        divTag: "",
        pTag: {
            p1: "",
            p2: "",
        },
        callBack: null,
        callBackName: "",
    },
});
export const alertLendingState = atom({
    key: "alertLendingState",
    // key의 값은 항상 고유값이어야 합니다.
    default: {
        type: "",
    },
});
export const energyState = atom({
    key: "energyState",
    // key의 값은 항상 고유값이어야 합니다.
    default: {
        path: "custInfo",
        custNo: null,
        reload: false,
        ictg: "",
        selCost: null,
    },
});
export const todayState = atom({
    key: "todayState",
    // key의 값은 항상 고유값이어야 합니다.
    default: null,
});
export const noneUrl = atom({
    key: "noneUrl",
    // key의 값은 항상 고유값이어야 합니다.
    default: true,
});
export const deviceState = atom({
    key: "deviceState",
    // key의 값은 항상 고유값이어야 합니다.
    default: {
        id: 1,
        name: "등록된 설비",
    },
});
/* export const deviceScore = atom({
  key: 'deviceScore',
  // key의 값은 항상 고유값이어야 합니다.
  default: -1

}); */
/* SEI 01 */
export const confirmState = atom({
    key: "confirmState",
    // key의 값은 항상 고유값이어야 합니다.
    default: {
        header: "",
        layerId: "",
        content: "",
        leftBtnName: "취소",
        leftBtnCallBack: null,
        rightBtnName: "등록",
        rightBtnCallBack: null,
    },
});
export const energySolutoinState = atom({
    key: "energySolutoinState",
    default: {
        id: null,
        zoneId: null,
        zoneName: null,
        custNo: null,
        companyId: null,
    },
});
export const energySolutoinHistory = atom({
    key: "energySolutoinHistory",
    default: null,
});
export const energySolutoinHistoryHight = atom({
    key: "energySolutoinHistoryHight",
    default: null,
});
export const errorListState = atom({
    key: "errorListState",
    default: [],
});
//
export const energyTab = atom({
    key: "energyTab",
    // key의 값은 항상 고유값이어야 합니다.
    default: null,
});
export const energyBackTab = atom({
    key: "energyBackTab",
    // key의 값은 항상 고유값이어야 합니다.
    default: null,
});
export const popClose = atom({
    key: "popClose",
    // key의 값은 항상 고유값이어야 합니다.
    default: false,
});
