import { atom, selector } from "recoil";
import * as CONST from "../utils/Const";
import * as CUTIL from "../utils/commUtils";
import clog from "../utils/logUtils";
export const userInfoState = atom({
    key: "userInfoState",
    // key의 값은 항상 고유값이어야 합니다.
    default: {
        loginTime: "",
        userId: "",
        email: "",
        userName: "",
        phoneNumber: "",
        companyName: "",
        zoneName: "",
        department: "",
        role: "",
        language: "",
        classificationCode: -1,
        classification: "",
        agreeMailReceipt: false,
        agreeTos: false,
        agreePersonalInfo: false,
        agreeData: false,
        userIdPk: -1,
        isAutomatedNickName: false,
        nickName: "",
        userRank: "",
        userImage: "",
        loginInfo: { role: "", token: "", tokenExpireTime: "", refreshToken: "", refreshTokenExpireTime: "" },
    },
});
//
export const userInfoLoginState = selector({
    key: "userInfoSessionState",
    // key의 값은 항상 고유값이어야 합니다.
    get: ({ get }) => {
        const isAuth = get(authState);
        const userInfo = get(userInfoState);
        let tmpUserInfo = userInfo;
        // clog("IN RECOIL : USERINFOLOGINSTATE : " + isAuth + " : USERID : " + userInfo.userId);
        const sesToken = sessionStorage.getItem(CONST.STR_TOKEN);
        const sesUserId = localStorage.getItem(CONST.STR_USERID);
        if (isAuth && userInfo.userId.length <= 0) {
            return {
                loginTime: userInfo.loginTime,
                userId: isAuth && userInfo.userId.length <= 0 ? sesUserId : userInfo.userId,
                email: userInfo.email,
                userName: userInfo.userName,
                phoneNumber: userInfo.phoneNumber,
                companyName: userInfo.companyName,
                zoneName: userInfo.zoneName,
                department: userInfo.department,
                role: userInfo.role,
                language: userInfo.language,
                classificationCode: userInfo.classificationCode,
                classification: userInfo.classification,
                agreeMailReceipt: userInfo.agreeMailReceipt,
                agreeTos: userInfo.agreeTos,
                agreePersonalInfo: userInfo.agreePersonalInfo,
                agreeData: userInfo.agreeData,
                userIdPk: userInfo.userIdPk,
                isAutomatedNickName: userInfo.isAutomatedNickName,
                nickName: userInfo.nickName,
                userRank: userInfo.userRank,
                userImage: userInfo.userImage,
                loginInfo: {
                    role: "",
                    token: isAuth && userInfo.userId.length <= 0 ? sesToken : userInfo.loginInfo.token,
                    tokenExpireTime: userInfo.loginInfo.tokenExpireTime,
                    refreshToken: userInfo.loginInfo.refreshToken,
                    refreshTokenExpireTime: userInfo.loginInfo.refreshTokenExpireTime,
                },
            };
        } // isAuth 체크
        return userInfo;
    },
});
//authState
export const authState1 = selector({
    key: "authState1",
    // key의 값은 항상 고유값이어야 합니다.
    get: ({ get }) => {
        const userInfo = get(userInfoState);
        return userInfo.loginInfo.token.length > 0 ? true : false;
    },
});
export const authState = selector({
    key: "authState",
    // key의 값은 항상 고유값이어야 합니다.
    get: ({ get }) => {
        // 로그인 유지와 상관없이 동작
        const curTime = Date.parse(CUTIL.curTimeString()); // UTC 기준
        const userInfo = get(userInfoState);
        //
        let tokenTime = Date.parse(userInfo.loginInfo.tokenExpireTime);
        let diffSec = Math.round((tokenTime - curTime) / 1000);
        // 의미없는 체크 인가?
        if (userInfo.loginInfo.token.length > 0) {
            if (diffSec <= 0)
                return false; //
        }
        // 리프레쉬 등
        const sesToken = sessionStorage.getItem(CONST.STR_TOKEN);
        const sesTokenExpireTime = sessionStorage.getItem(CONST.STR_TOKEN_EXPIRETIME);
        clog("IN RECOIL : AUTHSTATE : SESSION TOKEN : " + sesToken);
        clog("IN RECOIL : AUTHSTATE : SESSION TOKEN EXTIME : " + sesTokenExpireTime);
        tokenTime = Date.parse(sesTokenExpireTime);
        diffSec = Math.round((tokenTime - curTime) / 1000);
        // 사용자 정보 재저장?
        if (sesToken == null) {
            return false; //
        }
        else if (sesToken && sesToken.length > 0) {
            if (diffSec <= 0)
                return false; //
        }
        return true;
    },
});
//authStateWithLocalStorage
export const authStateWithLocalStorage = selector({
    key: "authStateWithLocalStorage",
    // key의 값은 항상 고유값이어야 합니다.
    get: () => {
        const refreshToken = localStorage.getItem(CONST.STR_REFRESHTOKEN); // to localStorage
        return refreshToken.length > 0 ? true : false;
    },
});
export const autoLoginState = atom({
    key: "autoLoginState",
    // key의 값은 항상 고유값이어야 합니다.
    default: { attemptTimes: 0, attemptLastTime: "" },
});
export const autoLoginLockState = atom({
    key: "autoLoginLockState",
    // key의 값은 항상 고유값이어야 합니다.
    default: false,
});
export const itemInfoState = atom({
    key: "itemInfoState",
    // key의 값은 항상 고유값이어야 합니다.
    default: {
        id: -1,
        itemId: "",
        type: "",
        itemName: "",
        panelName: "",
        serialNo: "",
        spgId: -1,
    },
});
export const dailyState = atom({
    key: "dailyState",
    // key의 값은 항상 고유값이어야 합니다.
    default: {
        id: -1,
        scheduleId: -1,
        itemId: "",
        type: "",
        spgId: -1,
        status: 1,
    },
});
export const dailyInfo = atom({
    key: "dailyInfo",
    // key의 값은 항상 고유값이어야 합니다.
    default: {
        content: "",
        memoId: -1,
    },
});
export const momitolringInfo = atom({
    key: "momitolringInfo",
    // key의 값은 항상 고유값이어야 합니다.
    default: {
        content: "",
        memoId: -1,
    },
});
export const pageState = atom({
    key: "pageState",
    // key의 값은 항상 고유값이어야 합니다.
    default: {
        size: -1,
        totalElements: -1,
        totalPages: -1,
        number: -1,
    },
});
// rerload
export const reLoad = atom({
    key: "reLoad",
    // key의 값은 항상 고유값이어야 합니다.
    default: false,
});
export const reLoadPage = atom({
    key: "reLoadPage",
    // key의 값은 항상 고유값이어야 합니다.
    default: false,
});
export const reLoadState = atom({
    key: "reLoadState",
    // key의 값은 항상 고유값이어야 합니다.
    default: false,
});
export const reloadSpg = atom({
    key: "reloadSpg",
    // key의 값은 항상 고유값이어야 합니다.
    default: false,
});
export const reloadToady = atom({
    key: "reloadToady",
    // key의 값은 항상 고유값이어야 합니다.
    default: false,
});
export const reloadImage = atom({
    key: "reloadImage",
    // key의 값은 항상 고유값이어야 합니다.
    default: false,
});
export const reloadScroll = atom({
    key: "reloadScroll",
    // key의 값은 항상 고유값이어야 합니다.
    default: false,
});
// 상세 설비 팝업
export const infoPop = atom({
    key: "infoPop",
    // key의 값은 항상 고유값이어야 합니다.
    default: "",
});
export const reloadRight = atom({
    key: "reloadRight",
    // key의 값은 항상 고유값이어야 합니다.
    default: false,
});
export const reloadAlert = atom({
    key: "reloadAlert",
    // key의 값은 항상 고유값이어야 합니다.
    default: false,
});
export const reloadMyInfo = atom({
    key: "reloadMyInfo",
    // key의 값은 항상 고유값이어야 합니다.
    default: false,
});
export const myaccountMyInfo = atom({
    key: "myaccountMyInfo",
    // key의 값은 항상 고유값이어야 합니다.
    default: false,
});
export const findId = atom({
    key: "findId",
    // key의 값은 항상 고유값이어야 합니다.
    default: "",
});
export const comapanyInfo = atom({
    key: "comapanyInfo",
    // key의 값은 항상 고유값이어야 합니다.
    default: {
        companyId: -1,
        companyName: "",
    },
});
export const authId = atom({
    key: "authId",
    // key의 값은 항상 고유값이어야 합니다.
    default: "",
});
//데모용
export const demo = atom({
    key: "demo",
    // key의 값은 항상 고유값이어야 합니다.
    default: false,
});
/* SEI 01 */
export const communitySearchState = atom({
    key: "communitySearchState",
    // key의 값은 항상 고유값이어야 합니다.
    default: {
        searchKind: 1,
        stringLike: "",
        category: "",
        sort: "updatedTime,desc",
    },
});
export const communityIsMoveState = atom({
    key: "communityIsMoveState",
    // key의 값은 항상 고유값이어야 합니다.
    default: false,
});
export const communityPagingState = atom({
    key: "communityPagingState",
    // key의 값은 항상 고유값이어야 합니다.
    default: {
        totalElements: 0,
        totalPages: 0,
        number: 0,
        size: 9,
    },
});
export const selectedCommunityId = atom({
    key: "selectedCommunityId",
    default: 0,
});
export const communityPageState = atom({
    key: "communityPageState",
    default: "LIST",
});
export const communityUpdateState = atom({
    key: "communityUpdateState",
    default: false,
});
export const communityTag = atom({
    key: "communityTag",
    default: "",
});
export const isAutoNickname = atom({
    key: "isAutoNickname",
    default: false,
});
export const treamState = atom({
    key: "treamState",
    default: false,
});
/* mypage */
export const mypageState = atom({
    key: "mypageState",
    default: "NOTICE",
});
export const myPageBoardSearchState = atom({
    key: "myPageBoardSearchState",
    // key의 값은 항상 고유값이어야 합니다.
    default: {
        category: "",
        sort: "updatedTime,desc",
    },
});
export const mypageMineState = atom({
    key: "mypageMineState",
    default: true,
});
/* mypage */
