import dayjs from "dayjs";
import $ from "jquery";
import * as CONST from "./Const";
//sleep 기능
export function sleep(ms) {
    const loopTime = Date.now() + ms;
    while (Date.now() < loopTime) { }
}
export function frontLangSet(apiLang) {
    let lang = "";
    switch (apiLang) {
        case CONST.STR_APILANG_KOR: {
            lang = CONST.STR_LANG_KOR;
            break;
        }
        case CONST.STR_APILANG_ENG: {
            lang = CONST.STR_LANG_ENG;
            break;
        }
        case CONST.STR_APILANG_CHA: {
            lang = CONST.STR_LANG_CHA;
            break;
        }
        default: {
            lang = CONST.STR_LANG_KOR;
            break;
        }
    }
    return lang;
}
export function apiLangSet(flang) {
    let lang = "";
    switch (flang) {
        case CONST.STR_LANG_KOR: {
            lang = CONST.STR_APILANG_KOR;
            break;
        }
        case CONST.STR_LANG_ENG: {
            lang = CONST.STR_APILANG_ENG;
            break;
        }
        case CONST.STR_LANG_CHA: {
            lang = CONST.STR_APILANG_CHA;
            break;
        }
        default: {
            lang = CONST.STR_APILANG_KOR;
            break;
        }
    }
    return lang;
}
export function LangIdSet(flang) {
    let lang = 0;
    switch (flang) {
        case CONST.STR_LANG_KOR: {
            lang = 1;
            break;
        }
        case CONST.STR_LANG_ENG: {
            lang = 2;
            break;
        }
        case CONST.STR_LANG_CHA: {
            lang = 3;
            break;
        }
        default: {
            lang = 1;
            break;
        }
    }
    return lang;
}
//수식을 사용하는 이유는 대한민국 서울 시간에 맞추기 위함입니다. (기존 시간은 UTC 기준)
export function curTimeString() {
    return new Date(+new Date() + 3240 * 10000).toISOString().replace(/\..[0-9]*/, "");
}
// replace : /gi : 대소문자구분 없이 전체 문자열 치환
export function date2formedstr(date, format) {
    if (isnull(date))
        return "";
    if (isnull(format))
        return "";
    //var utc = ;
    var yyyyMMddhhmmss = new Date(+date + 3240 * 10000).toISOString();
    return utc2formedstr(yyyyMMddhhmmss, format);
}
export function utc2formedstr(utc, format) {
    if (isnull(utc))
        return "";
    if (isnull(format))
        return "";
    //var utc = ;
    var yyyyMMddhhmmss = utc.replace(/\..[0-9]*/, "").replace(/[\-:TZ]/gi, "");
    var rstr = "";
    var tp = 0;
    var yearL = 0, monthL = 0, dayL = 0, hourL = 0, minL = 0, secL = 0;
    for (var i = 0; i < format.length; i++) {
        if (format[i] == "Y" || format[i] == "y")
            yearL++;
        if (format[i] == "M") {
            if (yearL < 4) {
                tp = 2;
            }
        }
    }
    var timeFormatCha = "YyMDdhms";
    for (var i = 0; i < format.length; i++) {
        if (timeFormatCha.includes(format[i])) {
            rstr = rstr + yyyyMMddhhmmss[tp++];
        }
        else {
            rstr = rstr + format[i];
        }
    }
    return rstr;
}
//
export function utc2time(format, strTime) {
    return utc2formedstr(strTime, format);
}
export function curformattime(format) {
    return utc2formedstr(curTimeString(), format);
}
export function isnull(val) {
    var ret = false;
    // need object handle
    if (typeof val == "undefined") {
        ret = true;
    }
    else if (val == null) {
        ret = true;
    }
    else if (val.length <= 0) {
        ret = true;
    }
    return ret;
}
export function execCeilVal(num) {
    if (isnull(num))
        return 10;
    if (typeof num !== "number")
        return 10;
    var strNum = num.toString();
    var maxVal = Math.pow(10, strNum.length);
    var ratio = (num / maxVal) * 100;
    var calVal = ratio > 80
        ? maxVal
        : ratio > 50
            ? maxVal * 0.7
            : ratio > 30
                ? maxVal * 0.5
                : ratio > 20
                    ? maxVal * 0.3
                    : (maxVal * ratio) / 100;
    calVal = strNum.length === 1 ? 10 : calVal;
    return calVal;
}
// 한글은 preventDefault롤 방지가 안됨
export function beforeHandleComment(e, chklen) {
    // 키 입력 이전의 길이
    // 8 : backspace
    // 46 : delete
    var curVal = e.target.value;
    if (e.keyCode === 8 || e.keyCode === 46) {
        return;
    }
    if (curVal.length >= chklen) {
        e.preventDefault();
    }
}
// 한글은 preventDefault롤 방지가 안됨
// 제한 크기를 넘어가면, 글자 대치한다.
export function afterHandleComment(e, chkLen, callback) {
    var curVal = e.target.value;
    if (curVal.length >= chkLen) {
        callback(curVal.substring(0, chkLen));
    }
}
export function jsopen_Popup(e) {
    var actTag = e.currentTarget;
    if (isnull(actTag)) {
        actTag = e.target;
    }
    var activeLayer = actTag.getAttribute("data-pop");
    /*   // 레이어 팝업 화면 가운데 정렬
    $("#" + activeLayer + ".popup-layer").css("position", "absolute");
    $("#" + activeLayer + ".popup-layer").css(
      "top",
      ($(window).height() - $("#" + activeLayer).outerHeight()) / 2 +
        $(window).scrollTop() +
        "px"
    );
    $("#" + activeLayer + ".popup-layer").css(
      "left",
      ($(window).width() - $("#" + activeLayer).outerWidth()) / 2 +
        $(window).scrollLeft() +
        "px"
    ); */
    $("#" + activeLayer).removeClass("hidden"); //호출한 팝업만 부르기
    $(".dimm").stop().show().css("z-index", "30"); //배경 가져오기
    $("body").css("overflow-y", "hidden"); //body 스크롤 숨기기(화면고정)
    var mql2 = window.matchMedia("screen and (min-width: 501px)");
    if (mql2.matches) {
        $("body").css("overflow-y", "hidden"); //501이상에서 배경고정
    }
    else {
        $("body").css("overflow-y", "auto"); //501이하에서 배경 스크롤
    }
    //닫기 버튼 , 배경 클릭 시
    $("#" + activeLayer)
        .children()
        .children(".js-close")
        .on("click", function () {
        $("#" + activeLayer).addClass("hidden"); //모든 팝업 감추기
        $("body").css("overflow-y", "auto"); //body 스크롤 자동 원복
    });
    //230426, 팝업안에 팝업이 있는 경우 닫기 버튼 눌러도 dimm 유지
    $("#" + activeLayer + ".inner-popup")
        .children()
        .children(".js-close")
        .on("click", function () {
        $(".dimm").show().css("z-index", "11");
    });
    //로그아웃 팝업 닫기 버튼, 220726 추가
    //안내팝업 닫기 버튼, 220826 추가
    $("#" + activeLayer + ".popup-layer.popup-logout, .popup-layer.popup-basic")
        .children()
        .children()
        .children(".js-close, .btn-close")
        .on("click", function () {
        $("#" + activeLayer + ".popup-layer.popup-logout").addClass("hidden"); //모든 팝업 감추기
    });
    // 회원탈퇴여부팝업
    $("#" + activeLayer + ".popup-layer.popup-withdrawal, .popup-layer.popup-basic")
        .children()
        .children()
        .children(".js-close, .btn-close")
        .on("click", function () {
        $("#" + activeLayer + ".popup-layer.popup-withdrawal").addClass("hidden"); //모든 팝업 감추기
    });
    // 회원탈퇴완료팝업
    $("#" + activeLayer + ".popup-layer.popup-withdrawalcomplete, .popup-layer.popup-basic")
        .children()
        .children()
        .children(".js-close, .btn-close")
        .on("click", function () {
        $("#" + activeLayer + ".popup-layer.popup-withdrawalcomplete").addClass("hidden"); //모든 팝업 감추기
    });
    //이미지크롭 팝업 닫기 버튼, 220826 추가
    $("#" + activeLayer + ".popup-layer.popup-basic.imgcrop")
        .children()
        .children(".js-close, .btn-close")
        .on("click", function () {
        $("#" + activeLayer + ".popup-layer.popup-basic.imgcrop").addClass("hidden"); //모든 팝업 감추기
        $("body").css("overflow-y", "auto"); //body 스크롤 자동 원복
        $(".dimm").stop().hide().css("z-index", "11");
    });
}
export function jsopen_InnerPopup(activeLayer, actTag) {
    // 레이어 팝업 화면 가운데 정렬
    /*   $("#" + activeLayer + ".popup-layer").css("position", "absolute");
    $("#" + activeLayer + ".popup-layer").css(
      "top",
      ($(window).height() - $("#" + activeLayer).outerHeight()) / 2 +
        $(window).scrollTop() +
        "px"
    );
    $("#" + activeLayer + ".popup-layer").css(
      "left",
      ($(window).width() - $("#" + activeLayer).outerWidth()) / 2 +
        $(window).scrollLeft() +
        "px"
    ); */
    $("#" + actTag + ".popup-layer").css("z-index", "3"); // 기존에서 추가css('position', 'absolute')
    $("#" + activeLayer).removeClass("hidden"); //호출한 팝업만 부르기
    var mql2 = window.matchMedia("screen and (min-width: 501px)");
    if (mql2.matches) {
        $("body").css("overflow-y", "hidden"); //501이상에서 배경고정
    }
    else {
        $("body").css("overflow-y", "auto"); //501이하에서 배경 스크롤
    }
}
let cnt = 0;
export function jsopen_InteriorPopup(e) {
    var actTag = e.currentTarget;
    if (isnull(actTag)) {
        actTag = e.target;
    }
    var activeLayer = actTag.getAttribute("data-pop");
    let zIndex = 130;
    if (cnt < 1) {
        cnt++;
    }
    zIndex = zIndex + cnt;
    // 레이어 팝업 화면 가운데 정렬
    /*   $("#" + activeLayer + ".popup-layer").css("position", "absolute");
    $("#" + activeLayer + ".popup-layer").css(
      "top",
      ($(window).height() - $("#" + activeLayer).outerHeight()) / 2 +
        $(window).scrollTop() +
        "px"
    );
    $("#" + activeLayer + ".popup-layer").css(
      "left",
      ($(window).width() - $("#" + activeLayer).outerWidth()) / 2 +
        $(window).scrollLeft() +
        "px"
    ); */
    $("#" + activeLayer + ".popup-layer").css("z-index", zIndex); // z-index cnt
    // $("#" + activeLayer).addClass("pop-inner");
    $("#" + activeLayer).removeClass("hidden"); //호출한 팝업만 부르기
}
export function jsopen_ErrorAlertPopup(activeLayer) {
    let zIndex = 130;
    if (cnt < 1) {
        cnt++;
    }
    zIndex = zIndex + cnt;
    // 레이어 팝업 화면 가운데 정렬
    /*   $("#" + activeLayer + ".popup-layer").css("position", "absolute");
    $("#" + activeLayer + ".popup-layer").css(
      "top",
      ($(window).height() - $("#" + activeLayer).outerHeight()) / 2 +
        $(window).scrollTop() +
        "px"
    );
    $("#" + activeLayer + ".popup-layer").css(
      "left",
      ($(window).width() - $("#" + activeLayer).outerWidth()) / 2 +
        $(window).scrollLeft() +
        "px"
    ); */
    $("#" + activeLayer + ".popup-layer").css("z-index", zIndex); // z-index cnt
    // $("#" + activeLayer).addClass("pop-inner");
    $("#" + activeLayer).removeClass("hidden"); //호출한 팝업만 부르기
}
export function jsopen_AlertPopup(activeLayer) {
    // 레이어 팝업 화면 가운데 정렬
    let zIndex = 999;
    /* if(activeLayer == CONST.POP_CMM_CONFIRM && window.location.href.includes("community")){
      $("#" + activeLayer + ".popup-layer").css("position", "absolute");
    } else if (activeLayer != CONST.POP_CMM_CONFIRM) {
      $("#" + activeLayer + ".popup-layer").css("position", "absolute");
    }
    $("#" + activeLayer + ".popup-layer").css(
      "top",
      ($(window).height() - $("#" + activeLayer).outerHeight()) / 2 +
        $(window).scrollTop() +
        "px"
    );
    $("#" + activeLayer + ".popup-layer").css(
      "left",
      ($(window).width() - $("#" + activeLayer).outerWidth()) / 2 +
        $(window).scrollLeft() +
        "px"
    ); */
    if (activeLayer == CONST.POP_ALERT) {
        $("#" + activeLayer + ".popup-layer").css("z-index", zIndex); // z-index cnt
    }
    // const actTagId = document.getElementById(actTag)
    // if(!isnull(actTagId)) actTagId.setAttribute("style", "z-index: 3");
    $("#" + activeLayer).removeClass("hidden"); //호출한 팝업만 부르기
    $(".dimm").stop().show().css("z-index", "30"); //배경 가져오기
    $("body").css("overflow-y", "hidden"); //body 스크롤 숨기기(화면고정)
    var mql2 = window.matchMedia("screen and (min-width: 501px)");
    if (mql2.matches) {
        $("body").css("overflow-y", "hidden"); //501이상에서 배경고정
    }
    else {
        $("body").css("overflow-y", "auto"); //501이하에서 배경 스크롤
    }
}
export function jsopen_m_1650_Popup(e) {
    // e.preventDefault();
    var actTag = e.currentTarget;
    var activeLayer = actTag.getAttribute("data-pop");
    var mql11 = window.matchMedia("screen and (min-width: 1650px)"); //1650 이하에서 팝업으로 변경,
    if (mql11.matches) {
    }
    else {
        // $(".popup-layer").addClass("hidden"); //모든 팝업 감추기(팝업안에 팝업이 또 있을때는 해당 안됨)
        // 레이어 팝업 화면 가운데 정렬
        $("#" + activeLayer + ".box-detailinfo, .body-right.m-popup").addClass("popup-layer js-layer popup-type1"); //230518, .body-right.m-popup 클래스 추가
        $("#" + activeLayer + ".popup-layer").css("position", "fixed"); //230508, position fixed 로 수정
        $("#" + activeLayer + ".popup-layer").css("top", "0");
        $("#" + activeLayer + ".popup-layer").css("right", "0");
        //닫기 버튼 , 배경 클릭 시
        $("#" + activeLayer)
            .children()
            .children(".js-close")
            .on("click", function () {
            $("#" + activeLayer).addClass("m-hidden"); //모든 팝업 감추기
            $("body").css("overflow-y", "auto"); //body 스크롤 자동 원복
            $(".dimm").stop().hide().css("z-index", "11");
        });
        $("#" + activeLayer).removeClass("m-hidden"); //호출한 팝업만 부르기(m-hidden은 모바일에서만 팝업으로 되는 경우)
        $(".dimm").stop().show().css("z-index", "30"); //배경 가져오기
        $("body").css("overflow-y", "hidden"); //body 스크롤 숨기기(화면고정)
    }
}
export function jsopen_m_Popup(e) {
    e.preventDefault();
    var actTag = e.currentTarget;
    var activeLayer = actTag.getAttribute("data-pop");
    var mql3 = window.matchMedia("screen and (min-width: 940px)"); //220628(3) min-width값 변경,
    if (mql3.matches) {
        $("." + activeLayer).attr("");
    }
    else {
        // $(".popup-layer").addClass("hidden"); //모든 팝업 감추기(팝업안에 팝업이 또 있을때는 해당 안됨)
        $("#" + activeLayer).removeClass("hidden"); //호출한 팝업만 부르기
        $(".dimm").stop().show().css("z-index", "30"); //배경 가져오기
        $("body").css("overflow-y", "hidden"); //body 스크롤 숨기기(화면고정)
        //제일 바깥쪽 팝업을 닫았을때만 배경 감추기 (팝업안에 팝업이 또 있는 경우는 배경 살림)
        $("#" + activeLayer)
            .children()
            .children(".js-close")
            .on("click", function () {
            $(".dimm").stop().hide().css("z-index", "11");
        });
    }
    // 레이어 팝업 화면 가운데 정렬
    // $("#" + activeLayer + ".popup-layer").css("position", "absolute");
    // $("#" + activeLayer + ".popup-layer").css(
    //   "top",
    //   ($(window).height() - $("#" + activeLayer).outerHeight()) / 2 + $(window).scrollTop() + "px",
    // );
    // $("#" + activeLayer + ".popup-layer").css(
    //   "left",
    //   ($(window).width() - $("#" + activeLayer).outerWidth()) / 2 + $(window).scrollLeft() + "px",
    // );
    //닫기 버튼 , 배경 클릭 시
    $("#" + activeLayer)
        .children()
        .children(".js-close")
        .on("click", function () {
        $("#" + activeLayer).addClass("hidden"); //모든 팝업 감추기
        $("body").css("overflow-y", "auto"); //body 스크롤 자동 원복
    });
}
export function jsclose_Popup(layerId, className) {
    $("#" + layerId).addClass("hidden"); //모든 팝업 감추기
    /*   const element = (layerId == CONST.POP_ALERT) ? null : document.getElementById(layerId);
      console.log("element", element);
  
      if (element) {
        const bodyElement = element.getElementsByClassName('form__input')[0];
        console.log("bodyElement", element.getElementsByClassName('scrollH')[0].scrollTop);
  
        if (bodyElement) {
          bodyElement.scrollIntoView({
            block: "start"
          });
        }
      } */
    if (className) {
        IdScrollIntoView(layerId, className);
    }
    // $("#" + layerId + ".popup-layer").css("z-index", ""); // z-index cnt
    // $("#" + layerId + ".popup-layer").css("position", "");
    // $("#" + layerId + ".popup-layer").css("top", "");
    // $("#" + layerId + ".popup-layer").css("left", "");
    $(".dimm").stop().hide().css("z-index", "11");
    $("body").css("overflow-y", "auto"); //body 스크롤 자동 원복
    /*  const inner = document.querySelectorAll(".pop-inner")
     if (inner.length > 0) {
       $("#" + layerId).removeClass("pop-inner");
     } else {
       $(".dimm").stop().hide().css("z-index", "11");
       $("body").css("overflow-y", "auto"); //body 스크롤 자동 원복
     } */
}
export function jsclose_InPopup(layerId) {
    let zIndex = 130;
    if (cnt > 0) {
        cnt--;
    }
    zIndex = zIndex + cnt;
    $("#" + layerId).addClass("hidden"); //모든 팝업 감추기
    $("#" + layerId + ".popup-layer").css("z-index", ""); // z-index cnt
    // $("#" + layerId + ".popup-layer").css("top", "");
    // $("#" + layerId + ".popup-layer").css("left", "");
    // $("#" + layerId + ".popup-layer").css("position", "");
    // $("#" + layerId).removeClass("pop-inner");
}
export function jsclose_InnerPopup(layerId, actTagId) {
    //230426, 팝업안에 팝업이 있는 경우 닫기 버튼 눌러도 dimm 유지
    $("#" + layerId + ".popup-layer").addClass("hidden");
    $("#" + actTagId + ".popup-layer").css("z-index", "");
}
export function jsclose_AlertErrorPopup(layerId) {
    $("#" + layerId).addClass("hidden"); //모든 팝업 감추기
    // $("#" + layerId + ".popup-layer").css("top", "");
    // $("#" + layerId + ".popup-layer").css("left", "");
    // $("#" + layerId + ".popup-layer").css("position", "");
    // $("#" + layerId).removeClass("pop-inner");
}
// m-click-show className
export function mClickShow(e) {
    e.preventDefault();
    var actTag = e.currentTarget;
    var active = actTag.getAttribute("data-pop");
    var mql12 = window.matchMedia("screen and (min-width: 940px)");
    if (mql12.matches) {
        $("." + active).attr("");
        $("." + active).css("display", "flex");
    }
    else {
        $("#" + active).removeClass("m-none");
        $("." + active).addClass("m-none");
    }
    $("#" + active)
        .removeClass("m-none")
        .parent()
        .children(".pop-left")
        .addClass("m-none");
    $("#" + active)
        .parent()
        .parent()
        .parent()
        .children()
        .children(".go-prev")
        .removeClass("m-none");
    $("#" + active)
        .parent()
        .parent()
        .parent()
        .children()
        .children(".go-save")
        .removeClass("m-none");
    $("#" + active)
        .parent()
        .parent()
        .parent()
        .children()
        .children(".go-prev")
        .on("click", function () {
        $("#" + active).addClass("m-none");
        $("#" + active)
            .addClass("m-none")
            .parent()
            .children(".pop-left")
            .removeClass("m-none");
        $("#" + active)
            .parent()
            .parent()
            .parent()
            .children()
            .children("." + active)
            .removeClass("m-none");
        $("#" + active)
            .parent()
            .parent()
            .parent()
            .children()
            .children(".go-prev")
            .addClass("m-none");
        $("#" + active)
            .parent()
            .parent()
            .parent()
            .children()
            .children(".go-save")
            .addClass("m-none");
    });
}
/// default select box handler
export function selectOption(optionElement) {
    // 확장 가능
    const selectBox = optionElement.closest(".select");
    const selectedElement = selectBox.querySelector(".selected-value ");
    selectedElement.textContent = optionElement.textContent;
    var optionData = optionElement.getAttribute("data-value");
    selectedElement.setAttribute("data-value", optionData);
}
export function onClickSelect(e, handleSelect) {
    const selectBoxElement = e.currentTarget;
    const targetElement = e.target;
    const isOptionElement = targetElement.classList.contains("option");
    const closeCss = targetElement.offsetParent.classList[1];
    if (isOptionElement) {
        if (isnull(handleSelect)) {
            selectOption(targetElement);
        }
        else {
            handleSelect(targetElement);
        }
    }
    toggleSelectBox(selectBoxElement, closeCss);
}
// select active 액션
export function toggleSelectBox(selectBox, closeCss) {
    const selectCheck = closeCss;
    if (!selectCheck) {
        selectBox.classList.toggle("active");
    }
    else {
        selectBox.classList.toggle("active");
        // select class에 신규 클래스이름 추가하여 사용
        document.addEventListener("click", function (e) {
            const targetElement = e.target;
            const isSelect = targetElement.classList.contains("." + closeCss) || targetElement.closest("." + closeCss);
            if (isSelect) {
                return;
            }
            const allSelectBoxElements = document.querySelectorAll("." + closeCss);
            allSelectBoxElements.forEach((boxElement) => {
                boxElement.classList.remove("active");
            });
        });
    }
}
// tab 이동
export function onClickTab(props) {
    const tab = props.info;
    const url = props.hasOwnProperty("url") ? props.url : null;
    const setRecoilUrl = props.hasOwnProperty("recoil") ? props.recoil : null;
    const navigate = props.hasOwnProperty("nav") ? props.nav : null;
    const setCurTab = props.move;
    // url이 있을떄만 적용
    if (setRecoilUrl && navigate && url) {
        setRecoilUrl(url);
        navigate(url);
    }
    setCurTab(tab);
}
// 정렬 기능
export function onClickSort(props) {
    const { field, data, setData } = props;
    var selSort = data.sort === "asc" ? "desc" : "asc";
    if (field !== data.sortField) {
        // 정렬필드가 변경된 경우
        selSort = "desc";
    }
    setData({
        sortField: field,
        sort: selSort,
    });
}
// 검색 기능
export function onClickSearch(props) {
    // mobile id check
    const id = props.hasOwnProperty("id") ? props.id : null;
    const { text, setSearch } = props;
    // 검색 field moile , web check
    if (id) {
        var mobile = document.getElementById(id);
        const searchClassM = mobile.querySelector(".select");
        const searchClassChidrenM = searchClassM.querySelector(".selected-value ");
        var searchFieldM = searchClassChidrenM.getAttribute("data-value");
        setSearch({
            searchField: searchFieldM,
            searchText: text,
        });
    }
    else {
        var searchFind = document.body;
        const searchClass = searchFind.querySelector(".searcharea");
        const searchClassChidren = searchClass.querySelector(".selected-value ");
        var searchField = searchClassChidren.getAttribute("data-value");
        setSearch({
            searchField: searchField,
            searchText: text,
        });
    }
}
// 금액 기능
export function onChangeNum(num, setState, langId) {
    let state = null;
    let text = "";
    let unit = null;
    if (langId > 1) {
        if (num < 10000000) {
            state = (num / 1000).toLocaleString("en-US", {
                maximumFractionDigits: 0,
            });
            text = "천원";
            unit = "t";
        }
        else if (num < 100000000) {
            state = (num / 1000000).toLocaleString("en-US", {
                maximumFractionDigits: 0,
            });
            text = "천만원";
            unit = "m";
        }
        else {
            state = (num / 1000000).toLocaleString("en-US", {
                maximumFractionDigits: 0,
            });
            text = "억원";
            unit = "b";
        }
    }
    else {
        if (num < 10000000) {
            state = (num / 1000).toLocaleString("en-US", {
                maximumFractionDigits: 0,
            });
            text = "천원";
            unit = "t";
        }
        else if (num < 100000000) {
            state = (num / 10000000).toLocaleString("en-US", {
                maximumFractionDigits: 1,
            });
            text = "천만원";
            unit = "m";
        }
        else {
            state = (num / 100000000).toLocaleString("en-US", {
                maximumFractionDigits: 1,
            });
            text = "억원";
            unit = "b";
        }
    }
    return setState({
        num: state,
        txt: text,
        unit: unit,
    });
}
//금액 숫자만
export function Amount(num, langId) {
    let state = null;
    let unit = null;
    if (langId > 1) {
        if (num < 10000000) {
            state = (num / 1000).toLocaleString("en-US", {
                maximumFractionDigits: 0,
            });
            unit = "t";
        }
        else if (num < 100000000) {
            state = (num / 1000000).toLocaleString("en-US", {
                maximumFractionDigits: 0,
            });
            unit = "m";
        }
        else {
            state = (num / 1000000).toLocaleString("en-US", {
                maximumFractionDigits: 0,
            });
            unit = "b";
        }
    }
    else {
        if (num < 10000000) {
            state = (num / 1000).toLocaleString("en-US", {
                maximumFractionDigits: 0,
            });
            unit = "t";
        }
        else if (num < 100000000) {
            state = (num / 10000000).toLocaleString("en-US", {
                maximumFractionDigits: 1,
            });
            unit = "m";
        }
        else {
            state = (num / 100000000).toLocaleString("en-US", {
                maximumFractionDigits: 1,
            });
            unit = "b";
        }
    }
    return state;
}
export function AmountUnit(num, langId) {
    let state = null;
    let unit = null;
    if (langId > 1) {
        if (num < 10000000) {
            state = (num / 1000).toLocaleString("en-US", {
                maximumFractionDigits: 0,
            });
            unit = "K";
        }
        else if (num < 100000000) {
            state = (num / 1000000).toLocaleString("en-US", {
                maximumFractionDigits: 0,
            });
            unit = "M";
        }
        else {
            state = (num / 1000000).toLocaleString("en-US", {
                maximumFractionDigits: 0,
            });
            unit = "B";
        }
    }
    else {
        if (num < 10000000) {
            state = (num / 1000).toLocaleString("en-US", {
                maximumFractionDigits: 0,
            });
            unit = "K";
        }
        else if (num < 100000000) {
            state = (num / 10000000).toLocaleString("en-US", {
                maximumFractionDigits: 1,
            });
            unit = "M";
        }
        else {
            state = (num / 100000000).toLocaleString("en-US", {
                maximumFractionDigits: 1,
            });
            unit = "B";
        }
    }
    return unit;
}
// 시간 기능
export function onChangTime(num, setState) {
    let time = Math.floor(num);
    setState(time);
}
export function getCategoryClass(code) {
    let className = "";
    className = "set-" + code;
    return className;
}
export function setTodayDate(value, langId) {
    let today = dayjs(new Date());
    let compareValue = dayjs(value);
    let result = today.format("YYYYMMDD") == compareValue.format("YYYYMMDD")
        ? Number(today.format("HH")) - Number(compareValue.format("HH")) + `${langId == 1 ? "시간 전" : "hours ago"}`
        : dayjs(value).format("YY-MM-DD");
    return result;
}
/*
 **  num : 비트값
 **  i : 인덱스
 */
export function getBit(num, i) {
    let bit = num.toString(2);
    bit = bit.length == 1 ? "00" + bit : bit.length == 2 ? "0" + bit : bit;
    if (bit.substr(i - 1, 1) == "1") {
        return true;
    }
    else {
        return false;
    }
}
export function getRoleName(roleName) {
    if (isnull(roleName)) {
        return "";
    }
    else {
        let nm = roleName.split("_")[1];
        nm = nm.charAt(0) + nm.substr(1, nm.length).toLowerCase();
        return nm;
    }
}
// 숫자 콤마
export function comma(val, faolt) {
    let value = null;
    // faolt 있으면 자동 소수점 제공
    if (isnull(val))
        return (value = 0);
    if (faolt) {
        const parts = val === 0 || val === 0.0 ? 0 : val;
        value = CommaFalot(parts, faolt);
    }
    else {
        const parts = val === 0 || val === 0.0 ? 0 : val.toString().split(".");
        if (parts) {
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        value = !parts ? parts : parts.join(".");
    }
    return value;
}
//소수점
function CommaFalot(val, faolt) {
    const value = val.toString().split(".")[1];
    if (!value || value < 1) {
        return comma(val);
    }
    else {
        return val
            .toFixed(faolt)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}
export function inputNum(value, point) {
    let numValue = null;
    // point의 수에 따라 소수점 개수 표기됨
    if (point) {
        numValue = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, `$${point}`);
    }
    else {
        numValue = value.replace(/[^0-9]/g, "");
    }
    return numValue;
}
// 클래스명으로 스크롤
export function ClassNameScrollIntoView(className, num, left) {
    const bodyElement = document.getElementsByClassName(className)[0];
    if (left) {
        return (bodyElement.scrollLeft = num);
    }
    else {
        if (bodyElement) {
            if (num) {
                return bodyElement.scrollTo({
                    top: num,
                });
            }
            else {
                return bodyElement.scrollTo({
                    top: 0,
                });
            }
        }
    }
}
// id, className 스크롤
export function IdScrollIntoView(layerId, className, num) {
    const element = document.getElementById(layerId);
    if (element) {
        const bodyElement = element.getElementsByClassName(className)[0];
        if (bodyElement) {
            if (num) {
                return bodyElement.scrollTo({
                    top: num,
                });
            }
            else {
                return bodyElement.scrollTo({
                    top: 0,
                });
            }
        }
    }
}
// text 공백 자르기
export function textReplace(val, text) {
    let value = "";
    let regex = null;
    if (text) {
        const target = text.split(",");
        if (target.length > 1) {
            // regex = text
            //   .split(",")
            //   .map((item) => item.replace(new RegExp(`${item}`, "g"), ""));
            regex = new RegExp(`\\s*${text.split(",").map((item) => item)}\\s*`, "g");
            value = val.replace(regex, "");
        }
        else {
            regex = new RegExp(`\\s*${target[0]}\\s*`, "g");
            value = val.replace(regex, "");
        }
        // console.log("value", value);
    }
    else {
        const value = val.replace(/[ ]/g, "");
    }
}
// select 시작시 자동 체크
export const selectFoucs = (id, sub) => {
    const scrollIntoView = document.getElementById(id);
    // if (id.current) {
    //   id.current.focus();
    // }
    // sub props 별로 액션 이 달라짐
    if (sub) {
        // num 추가 시 스크롤 이 해당 위치로 이동
        // type에 대한 위치 수정
        if (sub.type == "c") {
            scrollIntoView.scrollIntoView({
                block: "center",
            });
        }
        else if (sub.type == "e") {
            scrollIntoView.scrollIntoView({
                block: "end",
            });
        }
        else {
            scrollIntoView.scrollIntoView({
                block: "start",
            });
        }
        if (sub.num !== null) {
            window.scrollTo({ top: sub.num });
        }
        // a or input foucs시
        if (sub.foucs == "input") {
            const scrollsId = document.getElementById("scrolls");
            const spgId = scrollsId.querySelector(`a[id="${id}"]`);
            spgId.scrollIntoView({ block: "center" }); // tree의 id
        }
    }
    else {
        scrollIntoView.scrollIntoView({
            block: "start",
        });
    }
    return;
};
