import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Logout from "@components/header/Logout";
import MyAccount from "@components/header/MyAccount";
import { loadingBoxState } from "@recoil/menuState";
import { URL_SEI } from "@utils/Const";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
export const DashBoardLayout = () => {
    const location = useLocation();
    const loading = useRecoilValue(loadingBoxState);
    const navigate = useNavigate();
    useEffect(() => {
        if (URL_SEI !== location.pathname) {
            navigate(location.pathname, { replace: true });
        }
    }, [location.pathname]);
    return (_jsxs(_Fragment, { children: [" ", _jsx(Outlet, {}), _jsx(Logout, {}), _jsx(MyAccount, {}), _jsx("div", { className: "dimm" }), loading && _jsx(Loading, {})] }));
};
function Loading(props) {
    const loadingBox = useRecoilValue(loadingBoxState);
    const isAuth = props.isAuth;
    return (_jsx("div", Object.assign({ className: !isAuth || loadingBox ? "loading" : "" }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB85C\uB529\uC911" })) })));
}
